import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { createFontProperties } from "@utils/theme-utils";

// green tone
const primary = {
    light: "#16C686",
    main: "#15A671",
    dark: "#10885C",
    contrastText: "#fafafa",
};
// navy tone
const secondary = {
    light: "rgba(0, 0, 0, 0.6)",
    main: "#3e4652",
    dark: "#2B313A",
    contrastText: "#ffffff",
};
const greyTone = {
    light: "#F5F6FA",
    main: "#D6D6D6",
    dark: "#BDBDBE",
    contrastText: "#ffffff",
};
const black = {
    light: "#8E8E8E",
    main: "#212121",
    dark: "#000000",
    contrastText: "#ffffff",
};
const error = {
    main: "#fe0404",
    contrastText: "#fff",
};

let theme = {
    palette: {
        primary: primary,
        secondary: secondary,
        grey: greyTone,
        blueGrey: "#3e4652",
        white: "#fff",
        black: black,
        error: error,
        text: {
            primary: secondary.main,
            secondary: primary.main,
            disabled: "rgba(0, 0, 0, 0.38)",
        },
        background: {
            default: "#fff",
        },
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                body: {
                    overflowY: "scroll",
                },
            },
        },
        MuiPaper: {
            elevation4: {
                boxShadow: "0 30px 160px 0 rgba(0, 0, 0, 0.15)",
            },
            elevation8: {
                boxShadow: "0 30px 160px 0 rgba(0, 0, 0, 0.15)",
            },
        },
        MuiChip: {
            label: {
                overflow: "visible",
            },
        },
        MuiFormControlLabel: {
            root: {
                background: "#F5F6FA",
                borderRadius: "4px",
            },
        },
    },
    shadows: [
        "-4.29px 8.91px 20px rgba(38, 157, 112, 0.17);",
    ],
    typography: {
        fontFamily: "Montserrat, Poppins",
        h1: createFontProperties({
            family: "Montserrat",
            weight: 700,
            size: "94.3px",
            letterSpacing: "-1.5px",
        }),
        h2: createFontProperties({
            family: "Montserrat",
            weight: 500,
            size: "59.8px",
            letterSpacing: "-0.5px",
        }),
        h3: createFontProperties({
            family: "Montserrat",
            weight: 400,
            size: "48.3px",
        }),
        h4: createFontProperties({
            family: "Montserrat",
            weight: 700,
            size: "33.4px",
            letterSpacing: "0.25px",
        }),
        h5: createFontProperties({
            family: "Montserrat",
            weight: 500,
            size: "23.9px",
        }),
        h6: createFontProperties({
            family: "Montserrat",
            weight: 400,
            size: "20.1px",
            letterSpacing: "0.25px",
        }),
        subtitle1: createFontProperties({
            family: "Montserrat",
            weight: 600,
            size: "15.8px",
            letterSpacing: "0.15px",
        }),
        subtitle2: createFontProperties({
            family: "Montserrat",
            weight: 600,
            size: "13.9px",
            letterSpacing: "0.1px",
        }),
        body1: createFontProperties({
            family: "Poppins",
            weight: 400,
            size: "15.5px",
            lineHeight: 1.81,
            letterSpacing: "0.5px",
        }),
        body2: createFontProperties({
            family: "Poppins",
            weight: 400,
            size: "13.5px",
            lineHeight: 1.2,
            letterSpacing: "0.25px",
        }),
        button: createFontProperties({
            family: "Poppins",
            weight: 400,
            size: "18px",
            lineHeight: 1.2,
            letterSpacing: "1.25px",
        }),
        chat: createFontProperties({
            family: "Poppins",
            weight: 400,
            size: "16px",
            lineHeight: 1.5,
        }),
        caption: createFontProperties({
            family: "Montserrat",
            weight: 400,
            size: "12.1px",
            lineHeight: 1.32,
            letterSpacing: "0.4px",
        }),
        overline: createFontProperties({
            family: "Montserrat",
            weight: 400,
            size: "12.1px",
            lineHeight: 1.32,
            letterSpacing: "2px",
        }),
    },
    spacing: [0, 6, 7, 12, 24, 40, 80, 160],
};

theme = createTheme(theme);
theme = responsiveFontSizes(theme);
export default theme;